@import './colors';
@import './fonts';
@import './bg';

.part-details {
  background-image: $gradient, $bg-details;
  padding-top: 5rem;
  padding-bottom: 6rem;

  &.diagram {
    min-height: 0 !important;
    padding-bottom: 3rem;
  }

  .top-bar-mobile {
    display: none;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    padding-left: 7rem;
    font-size: 1.75rem;
  }

  .top-bar {
    margin-left: auto;
    margin-right: auto;
    width: 54rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    min-height: 5rem;
    flex-wrap: wrap;

    h4 {
      color: $white;
      font-size: 1.255rem;
      letter-spacing: 0.125rem;
      margin-left: 2rem;
      font-weight: normal;
    }

    .search {
      margin: 0.5rem;
    }

    .toggle-button {
      color: $white;
      padding: 0.5rem 1rem;
      border: 1px $white solid;
      margin: 0.5rem;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0.9rem;
      line-height: 1.2;
    }

    .selected-model {
      color: $white;
      padding: 0.5rem 1.5rem;
      background-color: $black80;
      font-weight: bold;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      margin: 0.5rem;
    }

    .back-button-wrapper {
      width: 100%;
      min-width: 64rem;
      margin-top: 1rem;
      margin-left: 0.5rem;
      .back-button {
        background-color: rgba(0, 0, 0, 0.7);
        padding: .5rem 1rem;
        color: white;
        border: none;
        letter-spacing: 0.0625rem;
        height: 2.125rem;
        width: 7rem;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }


  .details-container {
    width: 54rem;
    display:flex;
    margin-left:auto;
    margin-right:auto;
    flex-direction: column;
    max-width: 100vw;

    p {
      color: $medium-gray;
      font-weight: bold;
      font-size: 0.75rem;
    }

    a {
      font-weight: bold;
    }

    .details-main-info {
      margin: 0.5rem;

      p {
        margin-bottom: 0;
        margin-top: 0;
        color: $gray;
      }

      h3 {
        margin-bottom: 0;
        color: $gray;
      }
    }

    .details-product {
      margin: 0.5rem;
      display: flex;
      width: 54rem;
      max-width: 100vw;
      flex-wrap: wrap;

      .product-image {
        width: 34rem;
        max-width: 100vw;
        margin-right: 2rem;

        img {
          width: 100%;
        }
      }

      .product-access {
        width: 16rem;
        padding-right: 2rem;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-access-top {

          .product-price {
            font-size: 2rem;
            color: $red;
            font-weight: bold;
          }

          .list {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            margin-bottom: 1rem;

            p {
              margin: 0;
            }

            a {
              text-decoration: none;
              color: $blue;
            }

            .price {
              color: $red;
              font-size: 0.875rem;
            }
          }
        }


      }
    }
    .details-description {
      width: 54rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      max-width: calc(100vw - 1rem);
      line-height: 1.3;
    }

    .diagram-image {
      width: 100%;
      max-width: calc(100vw - 1rem);
      margin: 0.5rem;
      border: 1px solid black;
      margin-top: 2rem;

      img {
        width: 100%;
      }

      &.alt {
        max-height: 55vh;
        height: 60vh;
        text-align: center;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        img {
          height: 100%;
          width: auto;
        }
      }
    }

    .table-wrapper {
      table {
        tr {
          th {
            &:nth-child(1) {
              text-align: center;
              padding-left: 0.5rem;
            }
            &:nth-child(4) {
              font-size: 0.75rem;
              width: 2rem;
              text-align:center;
              padding-left: 0.5rem;
            }
          }

          td {
            &:nth-child(1), &:nth-child(4) {
              text-align: center;
              padding-left: 0.5rem;
            }
          }
        }
      }
    }


  }

  @media screen and (max-width: 63.9375rem) {
    padding-top: 4.5rem;

    .alt {
      max-height: 48rem !important;
    }
  }
}
