@import './colors';
@import './fonts';

.search-results {
  background: linear-gradient(rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .2) 4rem, rgba(255, 255, 255, .8) 10rem, rgba(255, 255, 255, 1) 15rem, rgba(255, 255, 255, 1) 100%), url('../img/results-bg.jpg');
  padding-top: 5rem;
  padding-bottom: 3rem;
  min-height: 200vh !important;

  .top-bar-mobile {
    display: none;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    padding-left: 7rem;
    font-size: 1.75rem;
  }

  .top-bar {
    margin-left: auto;
    margin-right: auto;
    width: 54rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    min-height: 5rem;
    flex-wrap: wrap;

    h4 {
      color: $white;
      font-size: 1.255rem;
      letter-spacing: 0.125rem;
      margin-left: 2rem;
      font-weight: normal;
    }

    .search {
      margin: 0.5rem;
    }

    .toggle-button {
      color: $white;
      padding: 0.5rem 1rem;
      border: 1px $white solid;
      margin: 0.5rem;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0.9rem;
      line-height: 1.2;
    }

    .selected-model {
      color: $white;
      padding: 0.5rem 1.5rem;
      background-color: $black80;
      font-weight: bold;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      margin: 0.5rem;
    }

    .back-button-wrapper {
      width: 100%;
      min-width: 64rem;
      margin-top: 1rem;
      margin-left: 0.5rem;
      .back-button {
        background-color: rgba(0, 0, 0, 0.7);
        padding: .5rem 1rem;
        color: white;
        border: none;
        letter-spacing: 0.0625rem;
        height: 2.125rem;
        width: 7rem;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .three-col {
    display: flex;
    width: 100%;
    max-width: 54rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;

    .col-left {
      width: 9rem;
      margin-top: 5rem;
      min-width: 9rem;
      margin-left: 1rem;
      margin-right: 1rem;

      .filter-menu {
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;

        span {
          font-weight: bold;
          text-transform: uppercase;
        }

        a {
          text-decoration: none;
          color: $medium-gray;
          text-transform: uppercase;
          margin-top: 1rem;
        }
      }
    }

    .col-main {
      width: 100%;
      display: flex;
      flex-direction:column;
      max-width: calc(100% - 11rem);

      .product-result {
        width: 43rem;
        display: flex;
        flex-wrap: wrap;
        margin-right: 1rem;
        max-width:100%;
        justify-content: space-between;
      }

      .product-title {
        h3 {
          margin: 0;
          margin-bottom: 0.125rem;
        }
        h5 {
          margin: 0;
        }
        a {
          text-decoration: none;
          font-size: 0.75rem;
          color: $blue;
          font-weight: bold;
        }
      }
      .product-image {
        margin-top: 1rem;
        // margin-right: 3rem;
        width: 28rem;
        max-width: calc(100vw - 10rem);
        img {
          width: 100%;
        }
      }
      .product-links {
        width: 12rem;
        margin-top: 1rem;
        .product-price {
          color: $red;
          font-weight: bold;
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }
        .product-options {
          a {
            text-decoration: none;
            color: $blue;
            font-weight: bold;
            font-size: 0.75rem;
          }
        }
        .product-disclaimer {
          p {
            font-size: 0.75rem;
            color: $medium-gray;
            font-weight: bold;
          }
        }
      }
      .product-description {
        width: 28rem;
        p {
          font-size: 0.875rem;
          color: $medium-gray;
          font-weight: bold;
          span {
            color: $dark-gray;
          }
        }
      }
      .product-diagram-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        h5 {
          margin-bottom: 0.25rem;
          width:100%;
          text-transform: uppercase;
        }
        .links {
          width: 47.5%;
          display: flex;
          flex-direction: column;
          min-width: 12rem;
          a {
            font-size: 0.75rem;
            text-decoration: none;
            color: $medium-gray;
            margin-bottom: 0.25rem;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .divider {
        width: 28rem !important;
      }
    }
  }


  @media screen and (max-width: 63.9375rem) {
    padding-top: 4.5rem;
    background-position: center, center 3rem !important;
    background-size: contain !important;

    .three-col {
      .col-left {
        width: 6rem;
        min-width: 6rem;
      }

      .col-main {
        max-width: calc(100% - 9rem);

        .product-image {
          max-width: 100%;
        }
      }
    }
    .product-image {
      margin-right: 0 !important;
    }


  }
}
