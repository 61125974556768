@import './colors';
@import './fonts';
@import './bg';

.content {
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 20%;
  overflow:hidden;
  position: relative;

  background-image: $gradient, $bg-default;
  background-position: center, center -21vw;
  background-size: 100vw;

  .table-wrapper {
    width: 100%;
    max-width: calc(100vw - 1rem);
    margin: 0.5rem;
    overflow: auto;
    // max-height: 20vh;

    table {
      width: 100%;
      min-width: 54rem;
      text-transform: uppercase;
      border-collapse: collapse;
      font-weight: bold;
      font-size: 0.875rem;

      a {
        color: $blue;
        text-decoration: none;
      }

      tr {

        border: 2px $white solid;

        th {
          padding: 0.5rem;
          text-align: left;
          border: none;
          background-color: $dark-gray;
          color: $white;
          padding-left: 1rem;
        }
        td {
          padding: 0.5rem;
          text-align: left;
          border: 2px white solid;
          background-color: $black10;
          padding-left: 1rem;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 0;
    border: 1px solid $dark-gray;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  .product-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .qty-selector {
      display: flex;

      .qty {
        background-color: rgba(0, 0, 0, 0.7);
        padding: .5rem 1rem;
        color: white;
        border: none;
        letter-spacing: 0.0625rem;
        height: 1.125rem;
        width: 5rem;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        margin-right: 0.5rem;
      }

      input {
        text-align: center;
        padding-left: 0.5rem;
        font-weight: bold;
        border: 2px solid $medium-gray;
        font-size: 1.125rem;
      }
    }

    .add-to-cart {
      background-color: rgba(0, 0, 0, 0.7);
      padding: .5rem 1rem;
      color: white;
      border: none;
      letter-spacing: 0.0625rem;
      height: 1.125rem;
      width: 9.25rem;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      margin-top: 0.5rem;
      cursor: pointer;
    }
  }

  .carousel {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 20vh;

    .carousel-item {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;



      .carousel-hover {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .carousel-item-body {
          width: calc(100% - 4rem);
          background-color: $black50;
          color: white;
          font-size: 1.125rem;
          font-weight: 300;
          line-height: 2;
          padding: 2rem;
          position: absolute;
          left: 0;
          top: 25%;
        }


        .hover-text {
          color: white;
          font-size: 5rem;
          text-align: right;
          position: absolute;
          font-family: 'Univers';
          bottom: 0;
          right: 0.25rem;
          line-height: .85;
          letter-spacing: 0.0625rem;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }

      &:hover, &.active {
        .carousel-hover {
          display: flex;
          background-color: $black20;
        }
      }
    }
  }

  .text-box-wrapper {
    width: 100vw;
    // height: 100vh;
    display: flex;
    padding-top: 5rem;
    justify-content: center;
    flex-wrap: wrap;

    .text-box {
      background-color: $black70;
      width: 24rem;
      padding: 1rem 3rem;
      margin: 2rem;
      height: 30rem;
      color: white;
      line-height: 1.6;

      p {
        font-size: 14px;
        line-height: 2;
      }
    }
  }

  .bottom-text {
    color: $white;
    font-size: 1.5rem;
    white-space: nowrap;
    padding: 2rem 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    width: 60rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .0625rem;

    span {
      font-size: 4rem;
      padding: 0 1rem;
      font-family: 'Gota';
    }
  }

  input[type="text"] {
    background-color: #e2e2e2;
    color: $dark-gray;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    margin-top: .5rem;

    &::placeholder {
      text-transform: uppercase;
    }

    &.error {
      border: 1px $red solid;

      &::placeholder {
        color: $red;
      }
    }
  }

  input[type="number"] {
      text-align: center;
      padding-left: 0.5rem;
      font-weight: bold;
      border: 2px solid $medium-gray;
      font-size: 1rem;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: $white;
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-left: .75rem;
    border-radius: 0.125rem;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    border: 1px solid #adb8c0;

    &:active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

    &:checked {
    	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    	color: #99a1a7;
    }

    &:checked:after {
      font-family: 'Univers';
      content: '\2714';
    	font-size: 14px;
    	position: absolute;
    	top: 1px;
    	left: 1px;
    	color: $blue;
    }
  }

  @media screen and (max-width: 53.9375rem) {
    background-image: $mobile-gradient, $bg-default;
    background-position: center, center -4vw !important;
    background-size: contain !important;

    .bottom-text {
      display: none;
    }

    .carousel-hover {
      display: flex;
      background-color: $black20;
    }

    .text-box-wrapper {
      flex-direction: column;

      .text-box {
        width: auto;
        max-width: 24rem;
        min-width: 16rem;
        height: auto;
        margin-left: .5rem;
        margin-right: .5rem;

        form {
          .radio-wrapper {
            label {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 90rem) {
    .carousel {
      .carousel-item {
        .carousel-hover {
          .hover-text {
            font-size: 7rem;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 110rem) {
    .carousel {
      .carousel-item {
        .carousel-hover {
          .hover-text {
            font-size: 9rem;
          }
        }
      }
    }
  }

}
