$bg-default: url('../img/bg-template.jpg'); //fallback for any page without a background

$bg-home: url('../img/home-bg.jpg');

$bg-parts: url('../img/bg-template2.jpg');
$bg-parts-select: url('../img/bg-template2.jpg');
$bg-details: url('../img/bg-template.jpg'); //details.jpg

$bg-about: url('../img/about-bg.jpg');
$bg-contact: url('../img/contact-bg.jpg');
$bg-service: url('../img/home-bg.jpg');

$bg-checkout: url('../img/bg-template2.jpg'); //checkout.jpg
$bg-cart: url('../img/bg-template.jpg'); //cart-bg.jpg

$bg-gallery: url('../img/gallery-bg.jpg');
$bg-vehicles: url('../img/vehicles-bg.jpg');
