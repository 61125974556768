@import './colors';

.search {
  position: relative;
  width: 21rem;

  input[type="text"] {
    &.search-input {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid $white;
      height: 2rem;
      width: 20rem;
      padding-left: 0.5rem;
      color: $white;
      font-weight: normal;
      margin-top: 0;
      padding: 0;
      padding-left: .5rem;

      &::placeholder {
        color: $white;
      }
    }
  }


  .search-icon {
    position: absolute;
    right: 1rem;
    top: 0.5rem;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
