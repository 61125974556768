@import './colors';

.custom-select {
  position: relative;
  margin-top: .5rem;
  font-weight: bold;
  color: $medium-gray;
  width:48.75% !important;
  font-size: .75rem;
  text-transform: uppercase;
}

.custom-select select {
  display: none;
}

.select-selected {
  background-color: #e2e2e2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: $medium-gray transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent $medium-gray transparent;
  top: 7px;
}

.select-items div,.select-selected {
  color: $medium-gray;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.select-items {
  position: absolute;
  background-color: $white;
  border: 1px $dark-gray solid;
  border-top: none;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
