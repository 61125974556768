@import './colors';
@import './bg';

.content.service {
  background: linear-gradient($black50, $black50), $bg-service;

  .carousel {
    .carousel-item {
      width: 50vw;
      height: calc(50vw * .5);
      min-height: 50vh;

      &:nth-child(1) {
        background-image: url('../img/service1.jpg');
      }
      &:nth-child(2) {
        background-image: url('../img/service2.jpg');
      }
    }
  }

  @media screen and (max-width: 63.935rem) {
    .bottom-text {
      font-size: 1.25rem !important;
      justify-content: flex-start !important;
      margin-left: 2rem !important;
    }
  }

  @media screen and (max-width: 53.935rem) {
    .carousel {
      flex-direction: column;
      padding-top: 4rem !important;

      .carousel-item {
        width: 100%;
        height: calc(50vh - 2rem);
        min-height: auto;

        .carousel-hover {
          display: flex !important;
        }
      }
    }
  }
}
