@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/GOTHMMED.TTF');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/GOTHMBOL.TTF');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/GOTHMBIT.TTF');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: light;
  src: url('../fonts/GOTHMLIG.TTF');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: light;
  src: url('../fonts/GOTHMLIT.TTF');
}

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Univers.ttf');
}

@font-face {
  font-family: 'Gota';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Gota-Light.otf');
}
