@import './colors';
@import './fonts';
@import './bg';

.content.vehicles {
  background: linear-gradient(rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, .8) 100%), $bg-vehicles;

  background-position: center 50% !important;



  .content-wrapper {
    width: 64rem;
    height: calc(100vh - 9rem);
    display: flex;
    flex-wrap: nowrap;
    padding-top: 5rem;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;

    .left-panel {
      direction: rtl;
      display: flex;
      flex-wrap: wrap;
      width: 22rem;
      flex-direction: row-reverse;
      align-items: flex-start;
      max-height: calc(100vh - 8rem);
      overflow-y: auto;
      padding-left: 1rem;

      .vehicle-thumb {
        direction: ltr;
        width: 9.5rem;
        height: 6rem;
        margin: 0.125rem;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }

        .hover-text {
          position: absolute;
          width: 9.5rem;
          height: 6rem;
          left: 0rem;
          top: 0rem;
          background-color: $black50;
          color: $white;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: .75rem;
          font-weight: bold;
          display: none;
        }

        &:hover {
          .hover-text {
            display: flex;
          }
        }
      }
    }

    .right-content {
      width: 41rem;
      display: flex;
      padding-left: 1rem;


      .main-content {
        width: 35rem;
        display: flex;
        flex-direction: column;

        .main-image {
          width: 100%;
          position: relative;

          img {
            width: 100%;
          }

          .controls {

            .close-x {
              display: none;
              position: absolute;
              top: 1rem;
              right: 2rem;
              width: 2rem;
              height: 2rem;

              img {
                width: 100%;
                height: 100%;
              }
            }


            .arrow-left {
              position: absolute;
              transform: rotate(180deg);
              left: 1rem;
              height: 2rem;
              top: calc(50% - 1rem);
              cursor: pointer;
              img {
                height: 100%;
                width: auto;
              }
            }

            .arrow-right {
              position: absolute;
              transform: rotate(0deg);
              top: calc(50% - 1rem);
              height: 2rem;

              right: 1rem;
              cursor: pointer;

              img {
                height: 100%;
                width: auto;
              }
            }
          }
        }

        .titles {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-weight: bold;
          letter-spacing: 0.0625rem;
          font-size: 1rem;
          line-height: 2;
          color: $dark-gray;

          .main-titles {

          }

          .main-price {

          }
        }

        .description {
          line-height: 2;
          font-size: .825rem;
        }

        .contact-button {
          font-weight: bold;
          background-color: $black80;
          color: $white;
          padding: 0.5rem 1rem;
          width: 7rem;
          text-transform: uppercase;
          margin-top: 1.5rem;
          text-decoration: none;
        }
      }

      .image-scroll {
        width: 6rem;
        margin-left: 2rem;
        padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 22rem;
        padding-bottom: 2rem;
        justify-content: space-around;

        .small-image {
          width: 6rem;
          height: 4rem;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          &:nth-child(n+7) {
            display: none;
          }
        }

        .controls {
          .arrow-up {
            position: absolute;
            transform: rotate(270deg);
            left: calc(50% - .25rem);
            width: .5rem;
            cursor: pointer;
            top: 0;

            img {
              width: 100%;
              height: auto;
            }
          }

          .arrow-down {
            position: absolute;
            transform: rotate(90deg);
            left: calc(50% - .25rem);
            width: .5rem;
            cursor: pointer;
            bottom: 0;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 63.9375rem) {
    .content-wrapper {
      flex-direction: column;
      height: auto;
      width: 100%;
      .left-panel {
        width: 100%;
        padding-left: 0;
        max-height: none;
        padding-bottom: 50vh;

        .vehicle-thumb {
          margin: 0;
          width: 50%;
          height: auto;

          .hover-text {
            display: flex;
            height: 90%;
            width: 100%;
            background-color: transparent;
            justify-content: flex-end;
            font-size: 1rem;
          }
        }
      }

      .right-content {
        width: 100%;
        padding-left: 0;
        margin: 5rem 0;
        display: none;

        &.active {
          display: flex;
          position: absolute;
          top: 0rem;
        }

        .image-scroll {
          display: none;
        }

        .main-content {
          background-color: $white;
          width: 100%;

          .main-image {
            .controls {
              .close-x {
                display: flex;
                cursor: pointer;
              }
            }
          }

          .titles {
            width: calc(100% - 3rem);
            margin-left: 1rem;
          }

          .description {
            padding: 1rem;
          }

          .contact-button {
            margin-bottom: 1.5rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}
