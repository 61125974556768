@import './colors';

.footer {
  background-color: $black80;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  width: 100vw;

  position: absolute;
  bottom: 0;

  .footer-item {
    color: $white;
    font-size: 0.75rem;
    text-transform: uppercase;
    text-decoration: none;

    &a {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 63.9375rem) {
    display: none;
  }
}
