@import './colors';
@import './bg';

.content.checkout {
  background-image: $gradient, $bg-checkout;
  padding-top: 5rem;
  padding-bottom: 5rem;

  &.cart {
    background-image: $gradient, $bg-cart;
  }

  .flex-grid {
    display: flex;
    justify-content: space-between;
    width: 54rem;
    max-width: 100%;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    .flex-item {
      display: flex;
      flex-direction:column;
      width: 26.5rem;
      max-width: 100%;

      &.email-form {
        width: 23rem;
      }

      .payment-icons {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;

        img {
          width: 5rem;
        }
      }

      .info-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $dark-gray;
        color: $white;
        width: 100%;
        padding: 0.5rem 1rem;
        font-weight: bold;
        box-sizing: border-box;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        min-height: 2rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1.5;

        .checkbox {
          display:flex;
          align-items: center;
        }
      }

      &.row {
        flex-direction: row;
      }

      .name {
        display: flex;
        justify-content: space-between;

        >:nth-child(1) {
          width: 50%;
        }
        >:nth-child(2) {
          width: 33%;
        }
      }
    }

    &.product-table {
      padding: 0;
      max-width: 100%;

      .table-wrapper {
        max-width: 100%;
      }
    }
  }

  .checkout-top {
    display: flex;
    width: 54rem;
    max-width: 100%;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    .btns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 3rem;
    }

    .title {
      text-transform: uppercase;
      color: $white;
      margin-top: 1.25rem;
      font-weight: bold;
      font-size: 1.5rem;
      position: absolute;
      width: 15rem;
      text-align: center;
      left: calc(50% - 7.5rem);
    }
  }

  .btn {
    color: $white;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    margin-top: 1rem;

    &.red {
      background-color: $red;
    }

    &.black {
      background-color: $black;
    }
  }

  textarea {
    margin-top: 0.5rem;
    border: 2px solid $light-gray;
    padding: 0.5rem 1rem;
    color: $dark-gray;

    &::placeholder {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .checkout-login {
    margin-bottom: 2rem;

    input[type="text"] {
      &::placeholder {
        font-size: 0.75rem;
      }
    }
    .row {
      .btn {
        margin-top: 0.5rem;
        border: none;
      }
      >:nth-child(1) {
        width: 40%;
      }
      >:nth-child(2) {
        width: 35%;
      }
      >:nth-child(3) {
        width: 25%;
      }

      .checkbox {
        display: flex;
        align-items: center;
        margin-top: .5rem;
        padding-left: .5rem;
        background-color: #e2e2e2;
        margin-left:0.5rem;
        margin-right:0.5rem;
        justify-content: center;

        label {
          font-size: 0.75rem;
          text-transform: uppercase;
          color: $medium-gray;
          font-weight: bold;
        }

        input {
        }
      }
    }
  }

  .checkout-cc {
    flex-direction: row;
    flex-wrap: wrap;
    >:nth-child(2), >:nth-child(3), >:nth-child(6) {
      width: 100%;
      box-sizing: border-box;
    }
    >:nth-child(4), >:nth-child(5) {
      width: 41.5%;
    }

    >:nth-child(4) {
      margin-right: 0.5rem;
    }

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      label {
        font-size: .625rem;
        color: gray;
        font-weight: bold;
      }


    }
    .btn {
      margin-left: auto;
    }
  }

  .disclaimer {
    font-size: 0.75rem;
    line-height: 1.5;
  }

  &.cart {
    .table-wrapper {
      margin: 0;
      margin-top: 2rem;
      overflow: hidden;
      table {
        min-width: 22.5rem;
        tr {
          border:none;
          th {
            font-size: 0.75rem;
            &:nth-child(1) {
              width: 100rem;
            }
            &:nth-child(2), &:nth-child(4) {
              width: 4rem;
            }
            &:nth-child(3) {
              width: 3rem;
            }
            &:nth-child(5) {
              width: 7rem;
            }
          }

          td {
            border-bottom: none;
            &:nth-child(1) {
              border-left: none;


              .product-part {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                >:nth-child(1) {
                  margin-right: 1rem;
                }

                >:nth-child(2) {
                  font-weight: normal;
                }
              }

            }
            &:nth-child(3) {
              padding: 0;

              input {
                line-height: 2;
                width: 3rem;
              }
            }
            &:nth-child(5) {
              color: $red;
              text-align: center;
              font-size: 1.5rem;
              cursor: pointer;
              padding-left: 0.5rem;
              font-weight: normal;
              border-right: none;
            }
          }

          &.submit {
            td {
              border: none;
              &:nth-child(1), &:nth-child(3) {
                background-color: transparent;
              }
              &:nth-child(2) {
                background-color: $red;

                text-align: center;

                a {
                  color: $white;
                  text-decoration: none;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .cart-text {
      width: 33.5rem;
      h4 {
        font-size: .875rem;
        margin-bottom: 0.5rem;
        margin-top: 2rem;

        &:nth-child(3) {
          max-width: 22rem;
        }
      }

      p {
        font-size: 0.625rem;
        line-height: 1.5;
        margin:0;

        &:nth-child(4) {
          max-width: 22rem;
        }
      }
    }



    .checkout-actions {
      width: 19.5rem;
      margin-top: 2rem;

      >div {
        display: flex;

        >div {
          background-clip: padding-box;
          background-color: $black10;
          text-transform: uppercase;
          line-height: 2.5;
          border: 2px solid white;
          font-weight: bold;
          font-size: .75rem;
          text-align: center;
          color: $dark-gray;
          &:nth-child(1) {
            width: 14.625rem;
          }
          &:nth-child(2) {
            width: 4.875rem;
          }
        }

        &.discount-code {
          margin-bottom: 2rem;
          input {
            background-clip: padding-box;
            height: 2.125rem;
            width: 14.625rem;
            box-sizing: border-box;
            margin-top: 0;
            color: $dark-gray;
            background-color: white;
            border: 2px $dark-gray solid;

            &::placeholder {
              text-align: center;
            }
          }
          a {
            background-clip: padding-box;
            line-height: 2.75;
            // border: 2px solid white;
            width: 4.875rem;
            background-color: $black10;
            text-transform: uppercase;
            font-weight: bold;
            font-size: .75rem;
            text-align: center;
            color: $dark-gray;
            text-decoration: none;
          }
        }

        &.grandtotal-row {
          margin-top: 1rem;
          >div {
            color: $white;
            background-color: $black80;
          }
        }
      }
    }
  }

  @media screen and (max-width: 54rem) {
    background-image: $mobile-gradient, $bg-checkout;

    .flex-grid, .checkout-top {
      width: 26.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: calc(100vw - 2rem);

      .flex-item {
        &.email-form {
          width: 100%;
        }
      }
    }

    &.cart {
      background-image: $mobile-gradient, $bg-cart;
      .flex-grid, .checkout-top {
        &.full {
          width: 100%;
        }

      }

      .table-wrapper {
        table {
          tr {
            th {
              padding-left: 0.5rem;
            }
            td {
              padding-left: 0.5rem;
            }
          }
        }
      }
    }

    .bottom-cart {
      flex-direction: column-reverse;
      // margin-left: 0;
      margin-right: 0;
      width: 100%;

      .checkout-actions {
        margin-left: auto;
      }

    }

    .checkout-top {
      display: flex;
      flex-direction: column-reverse;

      .title {
        position: relative;
        text-align: left;
        left: 0;
      }
    }

    .btn {
      font-size: .875rem;
    }
  }
}
