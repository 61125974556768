@import './colors';
@import './bg';

.content.gallery {
  background-image: $gradient, $bg-gallery;

  .content-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    .menu-item {
      color: $dark-gray;
      text-transform: uppercase;
      padding: 2rem;
      letter-spacing: 0.125rem;

      &.active {
        font-weight: bold;
      }
    }
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 64rem;
    margin: 0 auto;

    .grid-item {
      width: 15rem;
      height: 9.75rem;
      position: relative;
      margin-bottom: 1rem;
      cursor: pointer;
      .item-image {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .hover-text {
        position: absolute;
        left: 0;
        top: 0;
        width: 15rem;
        height: 9.75rem;
        text-transform: uppercase;
        display: none;
        align-items: center;
        justify-content: center;
        background-color: $black50;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;

      }

      &:hover {
        .hover-text {
          display: flex;
        }
      }
    }
  }

  @media screen and (max-width: 53.9375rem) {
    background-image: $mobile-gradient, $bg-gallery;
    .content-menu {
      display: none;
    }

    .image-grid {
      width: 100%;
      margin-top:4rem;

      .grid-item {
        max-width: 50%;
        margin-bottom: 0;

        .hover-text {
          display: flex;
          background-color: transparent;
          align-items: flex-end;
          width: 100%;
          height: 9rem;
        }
      }
    }
  }
}
