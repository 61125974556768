@import './fonts';

body {
  margin: 0;
}

* {
  font-family: 'Gotham', sans-serif;
}

h3 {
  text-transform: uppercase;
}
