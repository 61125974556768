$white: #fff;
$white70: rgba(255, 255, 255, 0.7);
$black: #000;
$black80: rgba(0, 0, 0, 0.8);
$black20: rgba(0, 0, 0, 0.2);
$black10: rgba(0, 0, 0, 0.1);
$black30: rgba(0, 0, 0, 0.3);
$black50: rgba(0, 0, 0, 0.5);
$black70: rgba(0, 0, 0, 0.7);
$gray: #393939;
$dark-gray: #272626;
$light-gray: #b2b2b2;
$medium-gray: #828282;
$red: #db3b31;
$blue: #5282D0;

$gradient: linear-gradient(rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .2) 4rem, rgba(255, 255, 255, .6) 10rem, rgba(255, 255, 255, 1) 25rem, rgba(255, 255, 255, 1) 100%);
$mobile-gradient: linear-gradient(rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .2) 4rem, rgba(255, 255, 255, .6) 9rem, rgba(255, 255, 255, 1) 14rem, rgba(255, 255, 255, 1) 100%);
