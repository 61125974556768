@import './colors';
@import './bg';

.content.contact {
  background-image: $bg-contact;
  background-size: cover;

  .text-box-wrapper {
    padding-bottom: 5rem;
    .text-box {
      position: relative;
      form {
        display: flex;
        flex-direction: column;

        .radio-wrapper {
          display: flex;
          margin-bottom: 0.5rem;
          label {
            margin-right: 1.5rem;
            text-transform: uppercase;
            line-height: 1.875;
            font-size: .875rem;
          }
        }

        input[type="text"] {
          margin-bottom: 1rem;
          height: 2rem;
          padding-left:0.25rem;

          &::placeholder {
            text-transform: uppercase;
          }
        }

        textarea {
          padding:0.25rem;

          &::placeholder {
            text-transform: uppercase;
          }
        }

        input[type="submit"] {
          background-color: rgba(0, 0, 0, 0.5);
          color: white;
          position: absolute;
          bottom: -4rem;
          right: 0;
          border: none;
          letter-spacing: 0.0625rem;
          height: 2.125rem;
          width: 7rem;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;
        }

        .preferred-radios {
          position: relative;

          div {
            position: absolute;

            label {
              color: $light-gray;
              font-size: .875rem;
              line-height: 1.875;
              vertical-align: top;
            }

            &:nth-child(1) {
              right: .5rem;
              top: .375rem;
            }

            &:nth-child(2) {
              right: 4.5rem;
              top: 3.75rem;
            }

            &:nth-child(3) {
              right: .5rem;
              top: 3.75rem;
            }
          }
        }
      }
    }
  }

  input[type="radio"] {
    width: 1.25rem;
    height: 1.25rem;
  }
}
