@import './colors';

.lightbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 1000;

  .lightbox-content {
    position: absolute;
    z-index: 1002;
    top: 2rem;
    left: calc((100vw - 48rem)/2);
    width: 48rem;
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding-bottom: 2rem;

    .lightbox-image {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .lightbox-desc {
      padding: 1rem;
      line-height: 2;
    }

    .controls {
      .arrow-left {
        position: absolute;
        transform: rotate(180deg);
        left: -2rem;
        height: 2rem;
            top: 17rem;
        cursor: pointer;
        img {
          height: 100%;
          width: auto;
        }
      }

      .arrow-right {
        position: absolute;
        transform: rotate(0deg);
            top: 17rem;
        height: 2rem;

        right: -2rem;
        cursor: pointer;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .lightbox-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background-color: $white70;
  }

  &.active {
    display: block;
  }

  @media screen and (max-width: 63.9375rem) {
    width: 100%;

    .lightbox-content {
      width: 100%;
      left: 0;
      top: 4rem;
    }
  }
}
