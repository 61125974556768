@import './colors';
@import './bg';

.content {
  &.home {
    background-image: linear-gradient($black50, $black50), $bg-home;

    .mobile-banner {
      display: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      height: 13rem;
      margin-top: 4rem;
      background-image: linear-gradient($black20, $black20), url('../img/service2.jpg');
      background-size: cover;

      color: $white;
      font-size: 1.25rem;
      .text {
        width: 60vw;
        text-align: right;
        padding-right: 1rem;
        margin-bottom: 1rem;
      }

      span {
        margin-top: 1rem;
        font-size: 4rem;
        padding: 0 1rem;
        width: 60vw;
        text-align: right;
        font-family: 'Gota';
      }
    }
    .carousel {
      .carousel-item {
        width: 33.34vw;
        height: calc(33vw * .75);
        min-height: 50vh;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          display: none;
          object-fit: cover;

          &.active {
            display: block;
          }
        }

        .carousel-hover {
          .hover-text-mobile {
            display: none;
            color: $white;
            font-size: 2rem;
            font-family: 'Gotham';
            font-weight: bold;
          }
        }
      }
    }

    @media only screen and (max-width: 63.9375rem) {
      .carousel {
        .carousel-item {
          min-height: 40vh;
        }
      }

      .bottom-text {
        font-size: 1rem;
      }
    }

    @media only screen and (max-width: 53.9375rem) {
      background: $white;

      .mobile-banner {
        display: flex;
        font-size: 1rem;
        letter-spacing: .125rem;
      }

      .carousel {
        flex-direction: column;
        padding-top: 1rem;

        .carousel-item {
          width: 100vw;
          height: 10rem;
          min-height: 10rem;
          margin-top: 1rem;

          .carousel-hover {
            align-items: center;
            justify-content: center;
            display: flex;

            .hover-text {
              display: none;
            }

            .hover-text-mobile {
              display: flex;
            }
          }
        }
      }
    }
  }
}
