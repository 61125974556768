@import './colors';

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  .header {
    background-color: $black80;
    height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: row;

    .hamburger {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;
      cursor: pointer;


      img {
        width:1.5rem;
        height: 1rem;
      }

      .flyout-menu {
        background-color: $white;
        display: none;
        flex-direction: column;

        position: absolute;
        left: 0;
        top: 4rem;

        .flyout-link {
          padding: 0.5rem 1rem;
          color: $black;
          text-transform: uppercase;
          line-height: 2;
          text-decoration: none;

          &:hover {
            background-color: $gray;
            color: $white;
          }
        }

        .mobile-menu {
          display: none;
          flex-direction: column;
        }
      }

      &:hover, &.active {
        .flyout-menu {
          display: flex;
        }
      }
    }

    .logo {
      img {
        height: 2rem;
        width: auto;
        padding: 1rem;
      }
    }

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }

    .nav {
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      a {
        color: $white;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 1rem;
        font-size: 0.75rem;

        &.active {
          font-weight: bold;
          line-height: 1.2;
          padding-top: 1.125rem;
          letter-spacing: 0.0625rem;
        }
      }

      .cart {
        margin-left: 0.5rem;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: 53.9375rem) {
    .header {
      background-color: $white;
      flex-direction: row-reverse;
      justify-content: space-between;

      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
      .nav {
        display: none;
      }

      .hamburger {
        margin-right: 2rem;
        .flyout-menu {
          right: 0;
          left: auto;
          .mobile-menu {
            display: flex;
          }

          .flyout-link {
            line-height: 3;
          }
        }
      }
    }
  }

  @media screen and (max-width: 22.5rem) {
    .header {
      .logo {
        img {
          height: 1.75rem;
        }
      }
    }
  }
}
