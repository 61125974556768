@import './colors';
@import './fonts';
@import './bg';

.content.parts {
  //background: linear-gradient(rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .2) 4rem, rgba(255, 255, 255, .8) 10rem, rgba(255, 255, 255, 1) 25rem, rgba(255, 255, 255, 1) 100%), url('../img/home-bg.jpg');
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 200vh !important;
  background-image: $gradient, $bg-parts;

  .top-bar-mobile {
    display: none;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    padding-left: 7rem;
    font-size: 1.75rem;
  }

  .top-bar {
    margin-left: 4rem;
    display: flex;
    align-items: center;
    height: 5rem;

    h4 {
      color: $white;
      font-size: 1.255rem;
      letter-spacing: 0.125rem;
      margin-left: 2rem;
      font-weight: normal;
    }

    .toggle-button {
      display: none;
      color: $white;
      padding: 0.5rem 1rem;
      border: 1px $white solid;
      margin: 0 1rem;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0.9rem;
      line-height: 1.2;
    }

    .selected-model {
      display: none;
      color: $white;
      padding: 0.5rem 1.5rem;
      background-color: $black80;
      font-weight: bold;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .model-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 64rem;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;

    .model {
      width: 31rem;
      height: 21rem;
      position: relative;

      .number {
        font-family: 'Gota', sans-serif;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 6rem;
        line-height: 2.5;
        cursor: pointer;
        z-index: 1;

        &.long {
          font-size: 3rem;
          top: 2.75rem;

          + .years {
            left: 16rem;
            top: -2.5rem;
          }
        }

        span {
          font-family: 'Gota', sans-serif;
        }
      }

      .years {
        display: flex;
        flex-direction: column;
        height: 10rem;
        justify-content: flex-end;
        position: absolute;
        left: 14rem;
        top: -1rem;
        flex-wrap: wrap;
        z-index: 2;
        padding-left: 1rem;

        a {
          padding-right: 1rem;
          font-size: .875rem;
          cursor: pointer;
          text-decoration: none;
          color: $black80;
        }
      }

      .car-image {
        position: absolute;
        bottom: 0;
        right: 0;

        img {
          width: 30rem;
        }
      }
    }
  }

  .items-container {
    display: none;
  }

  &.diagrams, &.items {
    background-image: $gradient, $bg-parts-select;
    .model-grid {
      display: none;
    }

    .top-bar {
      h4 {
        display: none;
      }

      .toggle-button {
        display: flex;
      }

      .selected-model {
        display: flex;
      }
    }

    .items-container {
      display: flex;
      width: 64rem;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 5rem;

      .left-bar {
        width: 20rem;
        display: flex;
        flex-direction: column;

        .filters {
          display: flex;
          flex-direction: column;
          width: 16rem;
          padding-top:1rem;
          margin-top: 1rem;

          a {
            color: $medium-gray;
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 2;
            text-transform: uppercase;
            cursor: pointer;

            &.active {
              color: $black;
            }
          }

          &.main {
            padding-top: 0;
            margin-top: 0;

            margin-bottom: 1rem;
          }

          &.sub {
            border-top: dotted 5px $black;
            color: $black30;

            a {
              color: $light-gray;
              font-style: italic;

              &.active {
                color: $black;
              }
            }
          }
        }
      }
    }
  }

  &.diagrams {
    .top-bar {
      .part {
        display: none;
      }
    }

    .items-container {
      .right-parts {
        display: none;
      }
      .right-diagrams {
        justify-content: flex-end;
        width: 44rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .diagram {
          width:21rem;
          border: 1px solid $black;
          padding: 0.25rem;
          margin: 0.125rem;
          background-color: white;

          img {
            width: calc(100%);
            height: calc(100%);
          }
        }
      }
    }
  }

  &.items {
    .top-bar {
      .diagram {
        display: none;
      }
    }

    .items-container {
      .right-diagrams {
        display: none;
      }
      .right-parts {
        width: 44rem;
        display: flex;
        flex-direction: column;

        .product {
          border: 1px $black solid;
          background-color: white;
          width: 40rem;
          height: 15rem;
          display: flex;
          padding: 1rem;
          margin-bottom: 2rem;

          .product-image {
            width: 25rem;
            height: 15rem;
            display: flex;
            align-items: center;
            justify-content: center;


            img {
              max-width: 25rem;
              max-height: 13rem;
            }
          }

          .product-details {
            width: 15rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 1rem;
            font-weight: bold;
            white-space: nowrap;

            .product-name {
              text-transform: uppercase;
              font-size: 1.75rem;
              padding-top: 1.5rem;
            }

            .product-subtitle {
              font-size: 1rem;
              padding-top: .5rem;
            }

            .price {
              font-size: 2rem;
              padding-top: 1.5rem;
              color: $red;
            }

            .part {
              font-size: .75rem;
              padding-top: .5rem;
            }

            .details-button {
              margin-top: 2rem;
              width: 6rem;
              height: 2rem;
              background-color: #b2b2b2;
              text-decoration: none;
              cursor: pointer;
              display: flex;
              /* padding: .5rem; */
              align-items: center;
              justify-content: center;

              span {
                color: white;
                text-transform: uppercase;
                line-height: 2;
                font-size: .75rem;
              }
            }
          }
        }
      }
    }


  }
  .pagination {
    display: flex;
    justify-content: flex-end;

    a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;

      &.active {
        background-color: $black;
        color: $white;
      }
    }
    a:hover:not(.active) {background-color: $light-gray;}
  }

  @media screen and (max-width: 53.9375rem) {
    background-image: $mobile-gradient, $bg-parts
  }

  @media screen and (max-width: 63.9375rem) {
    padding-top: 8.5rem;


    .top-bar {
      display: none;
    }

    .top-bar-mobile {
      display: flex;
      padding-left: 2rem;
    }

    &.items, &.diagrams {
      .top-bar {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-left: 2rem;
        height: 6rem;

        .selected-model {
          position: absolute;
          top: -2rem;
        }

        .search {
          input {
            border-color: $black;
            color: $black;

            &::placeholder {
              color: $black;
            }
          }
        }

        .toggle-button {
          color: $black;
          border-color: $black;
          text-transform: none;
          margin: 0;
        }

      }

      .top-bar-mobile {
        display: none;
      }

      .items-container {
        flex-direction: column;
        align-items: center;
        width: 100%;

        .left-bar {
          flex-direction: row;
          width: 100%;
          justify-content: space-around;

          .filters.sub {
            border-top: none;
            padding-top: 0;
            margin-top: 0;
          }

          .filters {
            max-width: 40%;
            a {
              font-size: 1rem;
            }
          }
        }

        .right-parts {
          width: 100%;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;

          .product {
            max-width: calc(100% - 3rem);
            width: 26rem;
            height: 12rem;

            .product-image {
              width: 15rem;
              height: 12rem;

              img {
                max-width: 100%;
                min-width: 100%;
              }
            }

            .product-details {
              width: 9rem;

              .product-name {
                padding-top: .5rem;
                font-size: 1.25rem;
              }

              .product-subtitle {
                font-size: .825rem;
              }

              .price {
                padding-top: .5rem;
              }
            }
          }
        }

        .right-diagrams {
          width: 100%;

          .diagram {
            width: calc(100% - 2rem);
            margin: 0.5rem 1rem;
          }
        }
      }
    }



    .model-grid {
      width: 100%;
      margin-bottom: 3rem;

      .model {
        max-width: calc(100% - 2rem);
        height: 27rem;

        &.short {
          height: 20rem;

          .years, .number.long + .years {
            height: 5rem;
          }
        }

        .car-image {
          top: 3rem;
          bottom: auto;
          width: 25rem;
          left: 0;
          img {
            width: 100%;
          }
        }

        .years, .number.long + .years {
          left: 0;
          bottom: 0;
          top: auto;
          height: 12rem;
          justify-content: flex-start;

          a {
            font-size: 1rem;
            line-height: 1.2;
          }
        }

        .number {
          line-height: 1.5;
        }
      }
    }
  }
}
