@import './colors';

.modal, .modal-cart {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  &.active {
    display: block;
  }
}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-btn {
  cursor: pointer;
}

.modal-content {
  background: $white !important;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;

  .product-list {
    max-width: 100%;
    overflow: hidden;
    height: 100%;

    display: flex;
    flex-direction: column;

    .product {
      width: 100%;
      display: flex;

      .product-image {
        width: 15rem;
        display:flex;
        align-items: center;
        max-height: 100%;


        img {
          width: 100%;
          height: auto;
        }
      }

      .product-info {
        width: calc(100% - 15rem);

        padding-left: 1rem;

        .product-title {
          * {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 1.5;
          }

          a {
            text-decoration: none;
            cursor: pointer;
            color: $blue;
            font-weight: bold;
          }
        }

        .product-description {
          font-weight: bold;
          color: $medium-gray;
        }

        .price-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 1.5rem;

          .product-price {
            color: $red;
            font-size: 1.5rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 53.9375rem) {
    width: calc(100% - 2rem);

    &.cart {
      padding-left: 0;
      margin-top: 5rem;
      h3 {
        margin-left: 1rem;
      }
    }
    .product-list {
      .product {
        flex-direction: column;

        .product-info {
          padding-left: 0;
          width: 100%;
        }
      }
    }
  }
}
